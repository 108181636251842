import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.deviceCapabilities.length}}\n  <AkStack @spacing='0.5' @flexWrap='wrap'>\n    {{#each this.deviceCapabilities as |capability|}}\n      <AkChip\n        @size='small'\n        @variant='outlined'\n        @label={{t capability}}\n        @color='error'\n        local-class='capability-chip'\n        data-test-fileDetails-dynamicScanDrawer-devicePrefTable-capability\n        data-test-fileDetails-dynamicScanDrawer-devicePrefTable-capabilityId='{{capability}}'\n      />\n    {{/each}}\n  </AkStack>\n\n{{else}}\n  <AkTypography\n    @variant='body1'\n    @color='textSecondary'\n    data-test-fileDetails-dynamicScanDrawer-devicePrefTable-noCapabilityText\n  >\n    <em>{{t 'modalCard.dynamicScan.noDeviceCapability'}}</em>\n  </AkTypography>\n{{/if}}", {"contents":"{{#if this.deviceCapabilities.length}}\n  <AkStack @spacing='0.5' @flexWrap='wrap'>\n    {{#each this.deviceCapabilities as |capability|}}\n      <AkChip\n        @size='small'\n        @variant='outlined'\n        @label={{t capability}}\n        @color='error'\n        local-class='capability-chip'\n        data-test-fileDetails-dynamicScanDrawer-devicePrefTable-capability\n        data-test-fileDetails-dynamicScanDrawer-devicePrefTable-capabilityId='{{capability}}'\n      />\n    {{/each}}\n  </AkStack>\n\n{{else}}\n  <AkTypography\n    @variant='body1'\n    @color='textSecondary'\n    data-test-fileDetails-dynamicScanDrawer-devicePrefTable-noCapabilityText\n  >\n    <em>{{t 'modalCard.dynamicScan.noDeviceCapability'}}</em>\n  </AkTypography>\n{{/if}}","moduleName":"irene/components/file-details/dynamic-scan/action/drawer/device-pref-table/device-capabilities/index.hbs","parseOptions":{"srcName":"irene/components/file-details/dynamic-scan/action/drawer/device-pref-table/device-capabilities/index.hbs"}});
import Component from '@glimmer/component';
import type AvailableManualDeviceModel from 'irene/models/available-manual-device';

enum CapabilitiesTranslationsMap {
  hasSim = 'sim',
  hasVpn = 'vpn',
  hasPinLock = 'pinLock',
  // hasVnc = 'vnc',
}

export interface FileDetailsDynamicScanDrawerDevicePrefTableDeviceCapabilitiesSignature {
  Args: {
    deviceProps: AvailableManualDeviceModel;
  };
}

export default class FileDetailsDynamicScanDrawerDevicePrefTableDeviceCapabilitiesComponent extends Component<FileDetailsDynamicScanDrawerDevicePrefTableDeviceCapabilitiesSignature> {
  get deviceCapabilities() {
    return Object.entries(CapabilitiesTranslationsMap)
      .filter(
        ([key]) =>
          this.args.deviceProps[key as keyof typeof CapabilitiesTranslationsMap]
      )
      .map(([, value]) => value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'file-details/dynamic-scan/action/drawer/device-pref-table/device-capabilities': typeof FileDetailsDynamicScanDrawerDevicePrefTableDeviceCapabilitiesComponent;
  }
}
